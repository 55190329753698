<template>
    <section class="login-page">
       <div class="login-area">
          <div class="row align-items-center justify-content-center height-self-center reset-pass">
             <div class="col-lg-10">
                <div class="auth-card">
                   <div class="card-body p-0">
                      <div class="d-flex align-items-center auth-content">
                         <div class="col-lg-12 align-self-center">
                            <div class="p-3">
                               <img class="logo" :src="logo">
                               <form @submit.prevent="onSubmit()">
                                  <div class="row">
                                     <div class="col-lg-12">
                                        <div class="floating-label form-group">
                                           <label>Email</label>
                                           <input v-model="email" class="floating-input form-control" type="email"
                                              placeholder="Email">
                                        </div>
                                        <div class="floating-label form-group">
                                           <label>New Password</label>
                                           <input v-model="password" class="floating-input form-control" type="password"
                                              placeholder="New Password">
                                        </div>
                                        <div class="floating-label form-group">
                                           <label>Confirm Password</label>
                                           <input v-model="confirm_password" class="floating-input form-control" type="password"
                                              placeholder="Confirm Password">
                                        </div>
                                     </div>
                                  </div>
                                  <button type="submit" class="btn btn-primary btn-block">Reset Password
                                  </button>
                                  <p class="mt-3">
                                     Return to login <router-link :to="{ name: 'auth.login' }"
                                        class="text-primary">Here</router-link>
                                  </p>
                               </form>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
       <div class="hero-area">
          <div class="content">
             <h1 class="mb-5">Set A New Password</h1>
             <p>If you have any questions, our team are here to help. Simply email support@omniautomotive.uk.
 </p>
          </div>
       </div>
    </section>
 </template>
 <script>
 import { mapGetters } from 'vuex'
 export default {
    name: 'ResetPassword',
    data() {
       return {
          email: '',
          password: '',
          confirm_password: ''
       }
    },
    computed: {
     ...mapGetters({
         logo: 'logo'
     })
 },
    methods: {
       onSubmit() {
          this.$store.dispatch('resetPassword', { token: this.$route.params.token, email: this.email, password: this.password, confirm_password: this.confirm_password});
       }
    }
 }
 </script>
 <style lang="scss">
 .reset-pass{
    width: 100%;
    max-width: 700px;
 }
 .logo{
    max-width: 120px;
    margin-bottom: 30px;
 }
 .login-page {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 100vh;
    width: 100%;
 
    .hero-area {
       width: 50%;
       background: #00112c;
       display: flex;
       justify-content: center;
       align-items: center;
       .content{
          padding: 40px;
          max-width: 800px;
          color: #fff;
          h1{
             color: #fff;
          }
       }
    }
 
    .login-area {
       display: flex;
       width: 50%;
       justify-content: center;
       align-items: center;
    }
 .form-control{
  background: #f3f6f9;
 }
 }
 </style>